/*------------- #PLUGINS --------------*/


@import "theme-styles/variables";

@import "plugins/swiper";
@import "plugins/select2";
@import "plugins/datepicker";
@import "plugins/perfect-scroll";
@import "plugins/jquery.datetimepicker";
@import "plugins/ion.rangeSlider";
@import "plugins/leaflet";